<template>
  <div class="r-chart-heatmap">
    <apex-chart
      type="heatmap"
      :height="chartOptions.chart.height || 'auto'"
      :width="chartOptions.chart.width || '100%'"
      :series="series"
      :options="chartOptions"
    />
  </div>
</template>

<script>
/**
 * RChartHeatmap
 *
 * Wrapper for ApexChart's heatmap chart with
 * some default chart options. Any options passed
 * in will overwrite defaults.
 */
import merge from 'lodash.merge'

import { getChartColorOptions } from '@/utils'

export default {
  name: 'RChartHeatmap',
  props: {
    series: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    chartOptions () {
      const defaults = {
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          heatmap: {
            // Add default background color
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#e7e7e7'
              }]
            }
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        colors: [getChartColorOptions().lightblue]
      }
      return merge(defaults, this.options)
    }
  }
}
</script>
