import { getDay, parseISO } from 'date-fns'

// hourOfDay -> label mappings
const labels = new Map()
labels.set('23', '11 PM')
  .set('22', '10 PM')
  .set('21', '9 PM')
  .set('20', '8 PM')
  .set('19', '7 PM')
  .set('18', '6 PM')
  .set('17', '5 PM')
  .set('16', '4 PM')
  .set('15', '3 PM')
  .set('14', '2 PM')
  .set('13', '1 PM')
  .set('12', '12 PM')
  .set('11', '11 AM')
  .set('10', '10 AM')
  .set('09', '9 AM')
  .set('08', '8 AM')
  .set('07', '7 AM')
  .set('06', '6 AM')
  .set('05', '5 AM')
  .set('04', '4 AM')
  .set('03', '3 AM')
  .set('02', '2 AM')
  .set('01', '1 AM')
  .set('00', '12 AM')

/**
 * Given a dataset of hourly data and a target set,
 * aggregate the hourly data and insert into the target
 * set on the given hour/day.
 *
 * Keys of the set are the hour of the day (eg. '13'),
 * and the value is an array of 7 items where each item
 * represents the total number of events for that day
 * (with Sunday being the first item in the array).
 *
 * @param {Object} dataset
 * @param {Set} data
 * @returns {Set}
 */
function addDailyTotals (dataset, data) {
  for (const [day, hours] of Object.entries(dataset)) {
    let dayOfWeek = getDay(parseISO(day))
    dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // make monday the start of week
    for (const [hour, count] of Object.entries(hours)) {
      const weeks = data.get(hour)
      weeks[dayOfWeek] += count
    }
  }
  return data
}

/**
 * heatmapMixin
 *
 * Vue mixin to generate hourly heatmap data.
 */
const heatmapMixin = {
  methods: {
    /**
     * Given an array of datasets, generate the series
     * data which can be fed into ApexChart's heatmap.
     *
     * Example dataset:
     *
     * {
     *   '2020-01-01': {
     *     '16': 1,
     *     '18': 3
     *   },
     *   '2020-01-03': {
     *     '16' : 1
     *   }
     * }
     *
     * Example response:
     *
     * [                         m  t  w  t  f  s  s
     *   { name: '4 PM', data: [ 0, 0, 0, 1, 0, 1, 0 ] },
     *   { name: '5 PM', data: [ 0, 0, 0, 0, 0, 0, 0 ] },
     *   { name: '6 PM', data: [ 0, 0, 0, 3, 0, 0, 0 ] }
     * ]
     *
     * @param {Array} datasets
     * @returns {Array}
     */
    $_heatmapMixin_generateSeries (datasets) {
      const hoursOfTheDay = labels.keys()
      const data = new Map() // use map to preserve order

      // Fill rows with default values for each day
      for (const hour of hoursOfTheDay) {
        data.set(hour, Array(7).fill(0))
      }
      // Add data
      for (const dataset of datasets) {
        addDailyTotals(dataset, data)
      }
      // Create series
      return Array.from(data.entries()).map(([hour, dailyTotals]) => {
        return {
          name: labels.get(hour),
          data: dailyTotals
        }
      })
    }
  }
}

export default heatmapMixin
