<template>
  <div class="r-chart-donut">
    <apex-chart
      v-if="series.length"
      type="donut"
      :height="chartOptions.chart.height || 'auto'"
      :width="chartOptions.chart.width || '100%'"
      :series="series"
      :options="chartOptions"
    />
    <div v-else>
      No Data
    </div>
  </div>
</template>

<script>
/**
 * RChartDonut
 *
 * Wrapper for ApexChart's donut-style pie chart with
 * some default chart options. Any options passed
 * in will overwrite defaults.
 */
import merge from 'lodash.merge'

import { capitalize, getChartColorSet } from '@/utils'

export default {
  name: 'RChartDonut',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    sortMethod: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    sortedKeys () {
      if (this.sortMethod) {
        return Object.keys(this.data).sort(this.sortMethod)
      }
      return Object.keys(this.data).sort((a, b) => {
        return -(this.data[a] - this.data[b])
      })
    },
    series () {
      return this.sortedKeys.map(key => this.data[key])
    },
    total () {
      return this.series.reduce((total, count) => total + count, 0)
    },
    chartOptions () {
      const defaults = {
        chart: {},
        labels: this.sortedKeys.map(label => capitalize(label)),
        plotOptions: {
          pie: {
            customScale: 1,
            expandOnClick: false,
            donut: {
              labels: {
                show: false
              }
            }
          }
        },
        stroke: {
          width: 0.5
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center',
          formatter: (seriesName, opts) => {
            const value = opts.w.globals.series[opts.seriesIndex]
            const percent = Math.round((value / this.total) * 100)
            return [
              `<span>${seriesName}</span>`,
              '<span style="padding: 0 10px"></span>',
              `<span>${percent}%</span>`
            ]
          },
          markers: {
            width: 8,
            height: 8
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: getChartColorSet(this.sortedKeys)
      }
      return merge(defaults, this.options)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-legend-series {
  display: flex;
  align-items: center;
}
::v-deep .apexcharts-legend-marker {
  margin-right: 8px;
  padding: 4px;
}
::v-deep .apexcharts-legend-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  font-family: $font-family-base !important;
}
</style>
