<template>
  <RChartHeatmap
    :series="series"
    :options="chartOptions"
  />
</template>

<script>
import merge from 'lodash.merge'

import { RChartHeatmap } from '@/components/charts'

export default {
  name: 'HeatmapChart',
  components: {
    RChartHeatmap
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: undefined
    }
  },
  computed: {
    chartOptions () {
      const defaults = {
        chart: {
          height: '345'
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: {
            // Hide every second hour label (limited space)
            formatter: (value, index) => {
              return index % 2 === 0 ? '' : value
            }
          }
        },
        labels: [ 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT', 'SUN' ],
        colors: [ this.color ]
      }
      return merge(defaults, this.options)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding-top: 0;
}
</style>
