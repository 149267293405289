<template>
  <ReportingCard :title="title">
    <RChartDonut
      :data="data"
      :options="options"
      :sort-method="sortMethod"
    />
  </ReportingCard>
</template>

<script>
import { RChartDonut } from '@/components/charts'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'LeadSourcesChart',
  components: {
    ReportingCard,
    RChartDonut
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Lead Sources'
    }
  },
  data () {
    return {
      options: {
        legend: {
          position: 'bottom'
        }
      }
    }
  },
  methods: {
    /**
     * Sort method to sort by Emails, Visits, Calls
     * in that order to match Lead Activity chart
     *
     * @param {String} a
     * @param {String} b
     * @returns {Number}
     */
    sortMethod (a, b) {
      const sortOrder = ['Emails', 'Visits', 'Calls']
      return sortOrder.indexOf(a) - sortOrder.indexOf(b)
    }
  }
}
</script>
