<template>
  <CCard>
    <CCardHeader>
      {{ title }}
      <span v-if="infoText">
        <CIcon
          v-c-tooltip="{
            content: infoText,
            color:'primary',
            placement:'top',
            boundaries: 'window'
          }"
          name="md-info"
          class="info-icon"
        />
      </span>
    </CCardHeader>
    <CCardBody>
      <slot />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'ReportingCard',
  props: {
    title: {
      type: String,
      required: true
    },
    infoText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  font-weight: 600;
  font-size: 1rem;
}
.info-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  color: $gray-400;
}
</style>
