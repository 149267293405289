<template>
  <div id="unlock-feature" class="pt-5">
    <CRow>
      <CCol>
        <div class="icon">
          <CIcon name="md-lock-open" height="100" />
        </div>
      </CCol>
    </CRow>
    <CRow class="pt-3">
      <CCol>
        <h2 class="title">
          Unlock {{ featureName }}
        </h2>
      </CCol>
    </CRow>
    <CRow class="py-3">
      <CCol>
        <div class="content">
          It looks like you currently do not have this feature activated.
          Please contact <a href="mailto:help@rooof.com" target="_blank">help@rooof.com</a>
          to add this service if you'd like to unlock {{ featureName }}.
        </div>
      </CCol>
    </CRow>
    <CRow class="pt-2">
      <CCol>
        <div class="footer">
          <a :href="featureLink" target="_blank">
            <CButton color="info" shape="pill" size="lg">
              Learn More
            </CButton>
          </a>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
/**
 * UnlockFeature
 *
 * A view which displays a "Unlock Feature [name]" message
 * instead of a reporting page when a user attempts to access
 * a report for a feature that they don't have an active product
 * subscription to.
 */
export default {
  name: 'UnlockFeature',
  props: {
    featureName: {
      type: String,
      required: true
    },
    featureLink: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
#unlock-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .c-icon {
    color: $primary;
    padding-bottom: 0.5rem;
    transform: scaleX(-1);
  }
  .title {
    font-weight: bold;
  }
  .content {
    width: 400px;
    font-size: 1rem;
    text-align: center;
  }
}
</style>
