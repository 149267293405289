<template>
  <ReportingCard title="Leads Per Hour">
    <HeatmapChart
      title="Leads Per Hour"
      :color="chartColors.green"
      :series="series"
    />
  </ReportingCard>
</template>

<script>
import { getChartColorOptions } from '@/utils'
import { HeatmapChart, ReportingCard } from '@/views/reporting/_components'
import { heatmapMixin } from '@/views/reporting/_mixins'

export default {
  name: 'LeadsPerHourChart',
  components: {
    ReportingCard,
    HeatmapChart
  },
  mixins: [
    heatmapMixin
  ],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    series () {
      return this.$_heatmapMixin_generateSeries([
        this.data.leads.emails.data,
        this.data.leads.phone.data,
        this.data.leads.redirects.data
      ])
    }
  },
  created () {
    this.chartColors = getChartColorOptions()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  padding: 0;
}
</style>
