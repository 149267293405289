<template>
  <div class="simple-widget-reporting">
    <RWidgetSimple :class="{'locked': locked}">
      <template #header>
        <div class="header">
          <span v-if="locked" class="lock-icon">
            <CIcon name="md-lock" />
          </span>
          <span v-else-if="infoText">
            <CIcon
              v-c-tooltip="{
                content: infoText,
                color:'primary',
                placement:'top',
                boundaries: 'window'
              }"
              name="md-info"
              class="info-icon"
            />
          </span>
          <span v-if="icon" class="header-icon" :class="{ 'is-link' : titleIsLink }">
            <CIcon :name="icon" height="20" />
          </span>
          <a v-if="titleIsLink && title.href" :href="title.href" target="_blank">
            {{ title.label }}
          </a>
          <CLink v-else-if="titleIsLink && title.to" :to="title.to">
            {{ title.label }}
          </CLink>
          <span v-else>
            {{ title }}
          </span>
        </div>
      </template>
      <div class="widget-content">
        <div v-if="locked" class="locked">
          Contact <a href="mailto:help@rooof.com" target="_blank">help@rooof.com</a>
          to unlock this feature!
        </div>
        <div v-else>
          {{ text || 'No Data' }}
        </div>
      </div>
    </RWidgetSimple>
  </div>
</template>

<script>
/**
 * Simple widget to display top-level reporting stats.
 *
 * `title` prop can be a string (default) or object (external link).
 */
import { RWidgetSimple } from '@/components/widgets'

export default {
  name: 'ReportingWidget',
  components: {
    RWidgetSimple
  },
  props: {
    title: {
      type: [String, Object],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Number],
      default: 'No Data'
    },
    locked: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: String,
      default: ''
    }
  },
  computed: {
    titleIsLink () {
      return typeof this.title === 'object'
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: $info;
}
.locked ::v-deep .card-body {
  background-color: #f6f9fb;
}
.header {
  color: $primary;
  .header-icon {
    padding-right: 0.5rem;
  }
  .is-link {
    color: $info;
  }
  .lock-icon {
    position: absolute;
    top: 2px;
    right: 5px;
    color: $gray-400;
  }
  .info-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    color: $gray-400;
  }
}
.widget-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  min-height: 2.6rem;
  .locked {
    font-size: 0.9rem;
    font-weight: 500;
  }
}
::v-deep .card {
  box-shadow: 1px 1px 5px 0px #dddddd !important;
}

</style>
