<template>
  <div id="summary-report">
    <portal to="report-subheader">
      <div class="subheader">
        {{ propertyAddress }}
      </div>
    </portal>
    <CRow>
      <CCol>
        <Widgets :data="data" :features="features" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <PostingActivityChart :data="data" :date-range="dateRange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="9">
        <PostingSummaryTable
          :data="data"
          :date-range="dateRange"
          :features="features"
          :loading="loading"
        />
      </CCol>
      <CCol col="3">
        <PostsPerHourChart :data="data" />
      </CCol>
    </CRow>
    <CRow v-if="!noLeadTracking">
      <CCol :col="Object.keys(leadSourcesData).length ? 9 : 12">
        <LeadActivityChart :data="data" :date-range="dateRange" :features="features" />
      </CCol>
      <CCol v-if="Object.keys(leadSourcesData).length" col="3">
        <LeadSourcesChart :data="leadSourcesData" />
      </CCol>
    </CRow>
    <CRow v-if="!noLeadTracking">
      <CCol col="9">
        <LeadSummaryTable
          :data="data"
          :date-range="dateRange"
          :features="features"
          :loading="loading"
        />
      </CCol>
      <CCol col="3">
        <LeadsPerHourChart :data="data" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { eachDayOfInterval, format, formatISO } from 'date-fns'

import { ReportingAPI } from '@/services/api/resources'
import { formatAddress } from '@/utils'

import LeadActivityChart from './LeadActivityChart'
import LeadSourcesChart from './LeadSourcesChart'
import LeadsPerHourChart from './LeadsPerHourChart'
import LeadSummaryTable from './LeadSummaryTable'
import PostingActivityChart from './PostingActivityChart'
import PostingSummaryTable from './PostingSummaryTable'
import PostsPerHourChart from './PostsPerHourChart'
import Widgets from './Widgets'

export default {
  name: 'OverviewReport',
  components: {
    Widgets,
    PostingActivityChart,
    PostingSummaryTable,
    PostsPerHourChart,
    LeadActivityChart,
    LeadSummaryTable,
    LeadsPerHourChart,
    LeadSourcesChart
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      data: {
        events: {
          posts: {
            data: {},
            total: 0
          },
          renews: {
            data: {},
            total: 0
          },
          price_updates: {
            data: {},
            total: 0
          },
          scheduled_posts: {
            data: {},
            total: 0
          }
        },
        leads: {
          emails: {
            data: {},
            total: 0
          },
          redirects: {
            data: {},
            total: 0
          },
          phone: {
            data: {},
            total: 0
          }
        }
      }
    }
  },
  computed: {
    propertyAddress () {
      return formatAddress(this.property.property.property_address)
    },
    noLeadTracking () {
      return !this.features.callTracking &&
      !this.features.emailHandling &&
      !this.features.redirectLinks
    },
    leadSourcesData () {
      const labelMap = {
        phone: 'Calls',
        redirects: 'Visits',
        emails: 'Emails'
      }

      const sources = {}
      for (const key in this.data.leads) {
        const total = this.data.leads[key].total
        if (total > 0) {
          sources[labelMap[key] || key] = total
        }
      }

      // Only display chart if there is more than 1 lead source
      return Object.keys(sources).length > 1 ? sources : {}
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.fetchOverviewReport()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Fetch the property overview report.
     */
    async fetchOverviewReport () {
      this.$emit('loading', true)
      const params = {
        from_date: formatISO(this.dateRange.start),
        to_date: formatISO(this.dateRange.end),
        tz: this.dateRange.tz
      }
      const response = await ReportingAPI.property.overview({
        id: this.$route.params.pid,
        params
      })
      if (response) {
        this.data = response.data
      }
      this.$emit('loading', false)
    },
    /**
     * onRefresh handler called by BaseReport component.
     */
    refresh () {
      this.fetchOverviewReport()
    },
    /**
     * onExport handler called by BaseReport component.
     *
     * @returns {Array}
     */
    exportData () {
      const data = eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      }).map(date => {
        return {
          date: format(date, 'yyyy-MM-dd'),
          posts: 0,
          renews: 0,
          price_updates: 0,
          scheduled_posts: 0,
          emails: 0,
          redirects: 0,
          phone: 0
        }
      })

      this.addDailyReportNumbers(this.data.events.posts.data, 'posts', data)
      this.addDailyReportNumbers(this.data.events.renews.data, 'renews', data)
      this.addDailyReportNumbers(this.data.events.price_updates.data, 'price_updates', data)
      this.addDailyReportNumbers(this.data.events.scheduled_posts.data, 'scheduled_posts', data)
      this.addDailyReportNumbers(this.data.leads.emails.data, 'emails', data)
      this.addDailyReportNumbers(this.data.leads.redirects.data, 'redirects', data)
      this.addDailyReportNumbers(this.data.leads.phone.data, 'phone', data)

      return data
    },
    /**
     * Sum hourly event/lead data into daily totals.
     *
     * Modifies `target` in-place.
     *
     * @param {Object} dataset
     * @param {String} key
     * @param {Array} target
     * @returns {Array}
     */
    addDailyReportNumbers (dataset, key, target) {
      // Transform hourly data into daily totals
      const days = Object.keys(dataset).map(day => {
        const hourlyData = Object.values(dataset[day])
        return {
          date: day,
          total: hourlyData.reduce((total, count) => total + count, 0)
        }
      })
      // Add totals to appropriate column of `target`
      for (const day of days) {
        const row = target.find(el => el.date === day.date)
        row[key] = day.total
      }
      return target
    }
  }
}
</script>

<style lang="scss" scoped>
.subheader {
  font-size: 1.2rem;
  color: $gray-600;
}
</style>
