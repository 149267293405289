<template>
  <div class="r-chart-column">
    <apex-chart
      type="bar"
      :height="chartOptions.chart.height || 'auto'"
      :width="chartOptions.chart.width || '100%'"
      :series="series"
      :options="chartOptions"
    />
  </div>
</template>

<script>
/**
 * RChartColumn
 *
 * Wrapper for ApexChart's stacked bar chart with
 * some default chart options. Any options passed
 * in will overwrite defaults.
 */
import merge from 'lodash.merge'

import { getChartColorSet, isNumeric } from '@/utils'

export default {
  name: 'RChartColumn',
  props: {
    series: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    chartOptions () {
      const defaults = {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        colors: getChartColorSet(this.series.map(value => value.name)),
        xaxis: {
          type: 'category',
          categories: []
        },
        yaxis: {
          lines: {
            show: true
          },
          labels: {
            formatter: value => {
              // Remove floating point precision from ints
              if (Number.isInteger(value)) {
                return parseInt(value, 10)
              }
              // If the value is a Number, but not an Integer, round it to 2 decimal places.
              if (isNumeric(value)) {
                return value.toFixed(2)
              }
              return value
            }
          }
        }
      }
      return merge(defaults, this.options)
    }
  }
}
</script>
