<template>
  <ReportingCard title="Posts Per Hour">
    <HeatmapChart
      title="Posts Per Hour"
      :series="series"
    />
  </ReportingCard>
</template>

<script>
import { HeatmapChart, ReportingCard } from '@/views/reporting/_components'
import { heatmapMixin } from '@/views/reporting/_mixins'

export default {
  name: 'PostsPerHourChart',
  components: {
    ReportingCard,
    HeatmapChart
  },
  mixins: [
    heatmapMixin
  ],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    series () {
      return this.$_heatmapMixin_generateSeries([
        this.data.events.posts.data
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  padding: 0;
}
</style>
