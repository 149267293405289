<template>
  <ReportingCard title="Lead Summary">
    <RSummaryTable :items="items" :fields="columns">
      <template #thead-top>
        <tr>
          <th colspan="1" class="table-header text-left">
            Date Range
          </th>
          <th colspan="7" class="table-header text-center gray-bg">
            Leads per Day
          </th>
          <th colspan="1" class="highlight" />
          <th v-if="!noLeadsPerSource" colspan="3" class="table-header text-center gray-bg">
            Leads per Source
          </th>
        </tr>
      </template>
    </RSummaryTable>
  </ReportingCard>
</template>

<script>
import { eachWeekOfInterval, endOfWeek, format, isSameWeek, parseISO } from 'date-fns'

import { RSummaryTable } from '@/components/tables'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'LeadSummaryTable',
  components: {
    ReportingCard,
    RSummaryTable
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    weeks () {
      return eachWeekOfInterval(
        {
          start: this.dateRange.start,
          end: this.dateRange.end
        },
        {
          weekStartsOn: 1 // Monday
        }
      )
    },
    items () {
      const data = this.getWeeks()
      if (!this.loading) {
        this.addLeadCounts(this.data.leads.emails.data, 'emails', data)
        this.addLeadCounts(this.data.leads.phone.data, 'calls', data)
        this.addLeadCounts(this.data.leads.redirects.data, 'visits', data)
      }
      return data
    },
    noLeadsPerSource () {
      return !this.features.callTracking && !this.features.emailHandling && !this.features.redirectLinks
    },
    columns () {
      return [
        { key: 'week', label: 'Week' },
        { key: 'mon', label: 'M', _classes: 'gray-bg' },
        { key: 'tue', label: 'T', _classes: 'gray-bg' },
        { key: 'wed', label: 'W', _classes: 'gray-bg' },
        { key: 'thu', label: 'T', _classes: 'gray-bg' },
        { key: 'fri', label: 'F', _classes: 'gray-bg' },
        { key: 'sat', label: 'S', _classes: 'gray-bg' },
        { key: 'sun', label: 'S', _classes: 'gray-bg' },
        { key: 'total', label: 'Total', _classes: 'highlight' },
        ...this.features.callTracking ? [{ key: 'calls', label: 'Calls', _classes: 'gray-bg' }] : [],
        ...this.features.emailHandling ? [{ key: 'emails', label: 'Emails', _classes: 'gray-bg' }] : [],
        ...this.features.redirectLinks ? [{ key: 'visits', label: 'Visits', _classes: 'gray-bg' }] : []
      ]
    }
  },
  methods: {
    /**
     * Create a new array where each item represents
     * a week in the interval.
     *
     * @returns {Array}
     */
    getWeeks () {
      return this.weeks.map(start => {
        const end = endOfWeek(start, { weekStartsOn: 1 })
        return {
          start,
          end,
          week: `${format(start, 'MMM dd')} - ${format(end, 'MMM dd')}`,
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
          total: 0,
          emails: 0,
          calls: 0,
          visits: 0
        }
      })
    },
    /**
     * Iterate over the given dataset and sum daily totals.
     *
     * Example dataset:
     *
     * {
     *   '2020-01-01': {
     *     '16': 1,
     *     '18': 3
     *   },
     *   '2020-01-03': {
     *     '09' : 1
     *   }
     * }
     *
     * Modifies `data` in-place.
     *
     * @param {Object} dataset
     * @param {String} key
     * @param {Array} data
     * @returns {Array}
     */
    addLeadCounts (dataset, key, data) {
      for (const [day, hours] of Object.entries(dataset)) {
        const date = parseISO(day)
        const week = data.find(week => isSameWeek(date, week.start, { weekStartsOn: 1 }))
        const count = Object.values(hours).reduce((total, count) => total + count, 0)
        const dayOfWeek = format(date, 'eee').toLowerCase() // wed

        if (week) {
          week[key] += count // leads per source
          week[dayOfWeek] += count // leads per day
          week.total += count
        }
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  font-weight: bold;
  font-size: 1rem;
}
::v-deep .gray-bg {
  background-color: $gray-50;
}
::v-deep .highlight {
  color: #fff;
  border: none;
  background-color: #03E397;
}
</style>
