<template>
  <ReportingCard title="Lead Activity">
    <RChartColumn :series="series" :options="chartOptions" />
  </ReportingCard>
</template>

<script>
import { eachDayOfInterval, format } from 'date-fns'

import { RChartColumn } from '@/components/charts'
import { getChartLabels, getDailyReportNumbers, getYAxisScale } from '@/utils'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'LeadActivityChart',
  components: {
    ReportingCard,
    RChartColumn
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      yTickAmount: 0
    }
  },
  computed: {
    days () {
      return eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      })
    },
    series () {
      return [
        ...this.features.emailHandling
          ? [{
            name: 'Emails',
            data: getDailyReportNumbers(this.data.leads.emails.data, this.days)
          }] : [],
        ...this.features.redirectLinks
          ? [{
            name: 'Visits',
            data: getDailyReportNumbers(this.data.leads.redirects.data, this.days)
          }] : [],
        ...this.features.callTracking
          ? [{
            name: 'Calls',
            data: getDailyReportNumbers(this.data.leads.phone.data, this.days)
          }] : []
      ]
    },
    chartOptions () {
      return {
        chart: {
          height: 300
        },
        // set y axis to scale of 5's
        yaxis: {
          min: 0,
          max: (max) => {
            const { maxYAxis, yTickAmount } = getYAxisScale(max)
            this.yTickAmount = yTickAmount
            return maxYAxis
          },
          tickAmount: this.yTickAmount
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true
          },
          categories: getChartLabels(this.days) // 09-Mar
        },
        tooltip: {
          x: {
            formatter: (value, series) => {
              const day = this.days[series.dataPointIndex]
              return format(day, 'dd-MMM (EEE)') // 09-Mar (Mon)
            }
          }
        },
        legend: {
          showForSingleSeries: true
        }
      }
    }
  }
}
</script>
