<template>
  <div id="new-feature-modal">
    <RModalSimple :show="show" @close="$emit('close')">
      <template #header>
        <div class="icon">
          <CIcon name="construction" height="100" />
        </div>
        <h2 class="title">
          New to {{ featureName }}?
        </h2>
      </template>
      <template #default>
        Heads up, it may take a few weeks to gather enough data
        to populate these report pages fully. But we promise that
        it'll be worth the wait!
      </template>
    </RModalSimple>
  </div>
</template>

<script>
/**
 * NewFeatureModal
 *
 * A modal which displays a "New to Feature [name]?" message
 * with some additional information on what the user should
 * expect.
 *
 * Events:
 *
 * @close: triggered when the export has completed
 */
import { RModalSimple } from '@/components/modals'

export default {
  name: 'NewFeatureModal',
  components: {
    RModalSimple
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    featureName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  .icon {
    color: $primary;
    padding-bottom: 0.5rem;
    transform: scaleX(-1);
  }
  .title {
    font-weight: bold;
    padding-top: 1rem;
  }
}
</style>
