<template>
  <ReportingCard title="Posting Activity">
    <RChartArea
      :series="series"
      :options="chartOptions"
    />
  </ReportingCard>
</template>

<script>
import { eachDayOfInterval, format } from 'date-fns'

import { RChartArea } from '@/components/charts'
import { getChartLabels, getDailyReportNumbers, getYAxisScale } from '@/utils'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'PostingActivityChart',
  components: {
    ReportingCard,
    RChartArea
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maxYAxis: 0,
      yTickAmount: 0
    }
  },
  computed: {
    days () {
      return eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      })
    },
    series () {
      return [{
        name: 'Posts',
        data: getDailyReportNumbers(this.data.events.posts.data, this.days)
      }, {
        name: 'Renews',
        data: getDailyReportNumbers(this.data.events.renews.data, this.days)
      }]
    },
    chartOptions () {
      return {
        chart: {
          height: 300
        },
        // set y axis to scale of 5's
        yaxis: {
          min: 0,
          max: (max) => {
            const { maxYAxis, yTickAmount } = getYAxisScale(max)
            this.yTickAmount = yTickAmount
            return maxYAxis
          },
          tickAmount: this.yTickAmount
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true
          },
          categories: getChartLabels(this.days) // 09-Mar
        },
        tooltip: {
          x: {
            formatter: (value, series) => {
              const day = this.days[series.dataPointIndex]
              return format(day, 'dd-MMM (EEE)') // 09-Mar (Mon)
            }
          }
        }
      }
    }
  }
}
</script>
